import React from "react"
import { I18nProvider } from "@lingui/react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import catalogDE from "../locales/de/messages"
import Layout from "../components/layout"
import { FaLink, FaMapMarkerAlt } from 'react-icons/fa';


import cto from "../assets/images/home/cto.png"
import cfo from "../assets/images/home/cfo.png"
import coo from "../assets/images/home/coo.png"
import ceo from "../assets/images/home/ceo.png"

import cornershopapp from "../assets/images/home/cornershopapp.png"
import auxmoney from "../assets/images/home/auxmoney.png"
import auto1group from "../assets/images/home/auto1-group.png"
import n26 from "../assets/images/home/n26.png"
import tourradar from "../assets/images/home/tourradar.png"
import bitmovin from "../assets/images/home/bitmovin.png"
import wikifolio from "../assets/images/home/wikifolio.png"
import cutiss from "../assets/images/home/cutiss.png"
import wingtra from "../assets/images/home/wingtra.png"
import piavita from "../assets/images/home/piavita.png"


export default ({ data }) => (
  <I18nProvider
    language="de"
    catalogs={{
      de: catalogDE,
    }}
  >
    <Layout
      pageType="home"
      image="/android-chrome-512x512.png"
      lang="de"
      title="Startupedia, die Enzyklopädie der Startups"
      description="Startupedia stellt Ihnen alle unentbehrlichen Informationen über Startups und Entrepreneurship mit Nachrichten und Artikeln über die Welt der Start-ups zur Verfügung"
      paths={{
        en: "/en",
        it: "/it",
        br: "/br",
        fr: "/fr",
        de: "/de",
        es: "",
      }}
    >
     <div className="wrapper mt-3">
        <div className="container">
          <h1>Die Enzyklopädie der Startups und des Unternehmertums</h1>
          <p>Startup ist ein Wort, das aus dem Englischen kommt und dessen wörtliche Bedeutung "in Bewegung setzen" ist. Aber was genau ist ein Startup, welche Arten von Startups gibt es ... All diese Fragen und noch viel mehr werden folgen.</p>
          
          <h2 className="pt-3">Was ist ein Startup?</h2>
          <p>Der Begriff Startup kommt aus dem Englischen und seine wörtliche Übersetzung lautet "Gründung". Obwohl es keine offizielle Definition gibt, könnte man sagen, dass ein Startup ein Geschäft oder eine unternehmerische Initiative mit den folgenden Merkmalen ist:
            <ul>
              <li>es auf Technologie basiert</li>
              <li>skalierbar ist</li>
              <li>in einer Umgebung mit maximaler Unsicherheit arbeitet</li>
            </ul>
            Zusätzlich zu den oben genannten Punkten unterscheidet sich ein Startup-Unternehmen von einem klassischen Unternehmen oder KMU dadurch, dass es sehr dynamisch ist und sich schnell an Veränderungen anpasst. Die Gründung dieser Art von Technologieunternehmen hat das Konzept des "Startupismus" oder Unternehmertums entstehen lassen, das nichts anderes ist als alles, was sich um diese Welt der Gründung von Unternehmen dreht, die den Sektor, in dem sie auftreten, revolutionieren. Klicken Sie hier, um mehr darüber zu erfahren, <Link to="/de/was-ist/startup/">was Startups sind</Link>.
          </p>

          <h2 className="pt-3">Rollen und Verantwortlichkeiten in einem Startup</h2>
          <p>Zwei oder drei Gründer mit unterschiedlichen Profilen sind Teil der Gründung oder Verfassung eines Startups. Jeder von ihnen bringt seine Expertise ein, um das Unternehmen erfolgreich zu machen. Im Folgenden finden Sie die typischen Rollen in einem Startup.</p>
          <div className="row">
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/de/was-ist/ceo-geschaeftsfuehrender-vorstand-chief-executive-officer/"><img src={ceo} alt="CEO" title="CEO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
              <Link to="/de/was-ist/cto-leiter-der-technologieabteilung-chief-technology-officer/"><img src={cto} alt="CTO" title="CTO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/de/was-ist/cfo-finanzvorstand-chief-financial-officer/"><img src={cfo} alt="CFO" title="CFO" /></Link>
            </div>
            <div className="text-center col-md-6 col-lg-3">
            <Link to="/de/was-ist/coo-vorstand-fuer-das-operative-geschaeft-chief-operating-officer/"><img src={coo} alt="COO" title="COO" /></Link>
            </div>
          </div>
          
          <h2 className="pt-3">Lean Startup</h2>
          <p>Die Lean-Startup-Methode wurde von Eric Ries geschaffen und in seinem Buch "The Lean Startup" entwickelt. Diese Methodik wird von Startups auf der ganzen Welt verwendet, um neue Geschäftsideen anzugehen, den Markt zu verstehen und zu wissen, wie man sie skaliert. Ziel ist es, Produktentwicklungszyklen zu verkürzen und schnell herauszufinden, ob ein vorgeschlagenes Geschäftsmodell tragfähig ist. Erreicht wird dies durch eine Kombination aus geschäftshypothesengesteuerten Experimenten, iterativen Produkteinführungen und validiertem Lernen. Heutzutage ist es sehr schwierig, Geld zu beschaffen, besonders in der Frühphase, ohne Lean Startup zu verstehen und umzusetzen. Erfahren Sie mehr darüber, <Link to="/de/was-ist/lean-startup/">was Lean Startup ist</Link>.</p>
          
          <h2 className="pt-3">Typen von Startups nach Geschäftsmodell</h2>
          <p>Startups, und jede andere Art von Unternehmen, lösen ein bestimmtes Problem für eine Gruppe von Nutzern. Je nach Sektor, in dem das Problem gelöst wird, können Startups unter anderem unterteilt werden in:
            <ul>
              <li><strong>Fintech Startups:</strong> Sie entwickeln ihre Lösung durch den Einsatz von <Link to="/de/was-ist/fintech-finanztechnologie/">Finanztechnologie</Link>. Beispiel: N26</li>
              <li><strong>Foodtech Startups:</strong> widmen sich der Verbesserung lebensmittelbezogener Prozesse. Beispiel: Planted</li>
              <li><strong>LegaltechStartups:</strong> Ihr Markt ist das Recht und die Anwaltschaft. Beispiel: Twinwin</li>
              <li><strong>Healhtech Startups:</strong> hauptsächlich fokussiert auf den Bereich der Altenpflege. Beispiel: Ada</li>
              <li><strong>Cleantech Startups:</strong> Automatisieren Sie Prozesse rund um die Reinigung von Textilien und Wohnungen. Beispiel: ZipJet</li>
              <li><strong>Edtech Startups:</strong> schaffen innovative Lösungen im Bildungsbereich. Beispiel: Udemy</li>
              <li><strong>Insurtech Startups:</strong> bieten ihren Kunden Dienstleistungen rund um das Thema Versicherung an. Beispiel: Coya</li>
              <li><strong>Proptech Startups:</strong> entwickeln Technologie mit Fokus auf den Immobiliensektor. Beispiel: Nestio</li>
            </ul></p>

            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <ins className="adsbygoogle"
                style={{display:"block", textAlign:"center"}}
                data-ad-layout="in-article"
                data-ad-format="fluid"
                data-ad-client="ca-pub-8800979330857975"
                data-ad-slot="6169923305"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            
          <h2 className="pt-3">Klassifizierung von Startups nach Land</h2>
          Überall auf der Welt gibt es innovative Startups. Hier sind einige Beispiele von Startups, klassifiziert nach ihren Herkunftsländern.
          <h3 className="pt-3">Deutsche Startups</h3>
          <p>Mit der größten Bevölkerung in Europa und einer diversifizierten Wirtschaft ist Deutschland ein führender Hub in Europa für Startups und Tech-Unternehmen. Die technologisch versierte und lösungsorientierte deutsche Bevölkerung bietet eine großartige Basis, um Startups bei ihrer nationalen und internationalen Expansion zu unterstützen.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={auxmoney} alt="Auxmoney" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Auxmoney</strong><br/>
                  Kredite <br/>
                  <FaLink /> https://www.auxmoney.com &nbsp; <FaMapMarkerAlt /> Frankfurt <br/>
                  Finanzdienstleistungsunternehmen, das einen Online-Marktplatz für Peer-to-Peer-Kredite betreibt.
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={auto1group} alt="Auto1 Group" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Auto1 Group</strong><br/>
                  Automobilindustrie <br/>
                  <FaLink /> https://www.auto1-group.com &nbsp; <FaMapMarkerAlt /> Berlin <br/>
                  Das Startup betreibt sein Geschäft hauptsächlich durch die Verbindung von Käufern und Verkäufern von Gebrauchtwagen über digitale Marktplätze
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={n26} alt="N26" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>N26</strong><br/>
                  Fintech <br/>
                  <FaLink /> https://n26.com &nbsp; <FaMapMarkerAlt /> Berlin <br/>
                  Sie bietet Girokonten, Festkonten und andere Bankdienstleistungen wie Bargeldabhebungen und -einzahlungen, internationale Geldtransfers, Investitionen und Kredite
                </p>
              </div>
            </div>
          </div>

          <h3 className="pt-3">Österreichische Startups</h3>
          <p>Obwohl es relativ neu auf der Startup-Landkarte ist, beheimatet Österreich eine junge und dynamische Tech-Startup-Szene. Sie hat bereits einige internationale Erfolgsgeschichten hervorgebracht, wie z. B. das Online-Reisebüro TourRadar, das 66,5 Millionen Euro an Finanzmitteln erhalten hat und in 200 Städten tätig ist.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={tourradar} alt="tourradar" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>TourRadar</strong><br/>
                  Reise <br/>
                  <FaLink /> https://www.tourradar.com &nbsp; <FaMapMarkerAlt /> Wien <br/>
                  Online-Reisebüro, das auf den Markt für Mehrtagesreisen abzielt. Es hat seinen Hauptsitz in Wien mit Büros in Toronto und Brisbane und bietet mehr als 25.000 Touren in 200 Städten
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={bitmovin} alt="bitmovin" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Bitmovin</strong><br/>
                  Video <br/>
                  <FaLink /> https://www.bitmovin.com &nbsp; <FaMapMarkerAlt /> Wien <br/>
                  Online-Video-Software- und Infrastruktur-Unternehmen, das sich zum Ziel gesetzt hat, die Wachstumsschmerzen des hochwertigen Streaming zu beseitigen
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={wikifolio} alt="wikifolio" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Wikifolio</strong><br/>
                  Fintech <br/>
                  <FaLink /> https://www.wikifolio.com &nbsp; <FaMapMarkerAlt /> Wien <br/>
                  Von Andreas Kern gegründete alternative Investmentplattform, die es Händlern ermöglicht, ihre Portfolios in vollwertige Finanzprodukte zu verwandeln
                </p>
              </div>
            </div>
          </div>

          <h3 className="pt-3">Schweizer Startups</h3>
          <p>Schweizer Startups, die von Venturelab unterstützt werden, haben über alle Branchen hinweg Tausende von Arbeitsplätzen geschaffen und insgesamt über 5 Milliarden Schweizer Franken von Investoren aus aller Welt eingeworben.</p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={cutiss} alt="cutiss" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Cutiss</strong><br/>
                  Biotech <br/>
                  <FaLink /> http://cutiss.swiss &nbsp; <FaMapMarkerAlt /> Zürich <br/>
                  Es züchtet im Labor menschliche Haut für Patienten, die an Hautdefekten (z. B. Verbrennungen) leiden
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={wingtra} alt="wingtra" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Wingtra</strong><br/>
                  Elektronik <br/>
                  <FaLink /> https://wingtra.com &nbsp; <FaMapMarkerAlt /> Zürich <br/>
                  Weltweit führender VTOL (vertical take off and landing) Drohnenhersteller
                </p>
              </div>
            </div>
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={piavita} alt="piavita" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>Piavita</strong><br/>
                  Medtech <br/>
                  <FaLink /> https://www.piavita.com &nbsp; <FaMapMarkerAlt /> Zürich <br/>
                  Das Piavita Vet System bietet Tierärzten eine einfache, umfassende und hochpräzise Lösung zur Vitalzeichenerkennung bei Pferden
                </p>
              </div>
            </div>
          </div>

          {/*<h3>Startups Alemanas</h3>
          <p></p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Card image cap" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>XXX</strong><br/>
                  XXX; XXX <br/>
                  <FaLink /> https://XXX &nbsp; <FaMapMarkerAlt /> XXX <br/>
                  XXX
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Estadounidenses</h3>
          <p></p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Card image cap" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>XXX</strong><br/>
                  XXX; XXX <br/>
                  <FaLink /> https://XXX &nbsp; <FaMapMarkerAlt /> XXX <br/>
                  XXX
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Francesas</h3>
          <p></p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Card image cap" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>XXX</strong><br/>
                  XXX; XXX <br/>
                  <FaLink /> https://XXX &nbsp; <FaMapMarkerAlt /> XXX <br/>
                  XXX
                </p>
              </div>
            </div>
          </div>

          <h3>Startups Italianas</h3>
          <p></p>
          <div className="row pt-3">
            <div class="card col-md-6 col-lg-4">
              <img class="mx-auto" style={{maxWidth:"25%"}} src={konfio} alt="Card image cap" />
              <div class="card-body">
                <p class="card-text text-center" style={{fontSize:"0.8rem"}}>
                  <strong>XXX</strong><br/>
                  XXX; XXX <br/>
                  <FaLink /> https://XXX &nbsp; <FaMapMarkerAlt /> XXX <br/>
                  XXX
                </p>
              </div>
            </div>
          </div>


          <h2 className="pt-3">Recursos para Startups</h2>
          <h2 className="pt-3">Trabajar en una Startup</h2>
          */}  
        
        </div>



        <div className="container mt-3">
          <h2>Neueste Blog-Artikel</h2>
          <div className="row">
            {/*<div className="col-sm-12 col-lg-9 col-xl-9">*/}
            <div className="col-12">
              <div className="container">
                <div className="row">
                  {data.allContentfulBlogPost.edges
                    .slice(0, 10)
                    .map(({ node }, index) => {
                      return (
                        <>
                          {/*<div className="col-md-6 mt-5">*/}
                          <div className="col-md-6 col-lg-4 mt-5">
                            <Link
                              to={`${getBlogPath(node.lang)}/${node.slug}/`}
                              title={`${getBlogPath(node.lang)}/${node.slug}/`}
                              style={{
                                textDecoration: "none",
                                color: "dimgrey",
                                fontSize: "0.9rem",
                              }}
                            >
                              <div className="card">
                                <Img
                                  alt={node.heroImage.title}
                                  title={node.heroImage.title}
                                  style={{ maxWidth: 450, maxHeight: 253 }}
                                  fadeIn="false"
                                  loading="eager"
                                  fluid={node.heroImage.fluid}
                                />
                                <div className="card-body">
                                  <h2 className="card-title">{node.title}</h2>
                                  <p className="card-text">{node.excerpt}</p>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-3 col-xl-3 mt-5 d-none">
              <img
                src="https://placehold.co/300x600"
                title="bla bla bla"
                alt="bla bla bla"
              />
              <div>&nbsp;</div>
              <img
                src="https://placehold.co/300x250"
                title="bla bla bla"
                alt="bla bla bla"
              />
            </div>
          </div>
          <p className="text-center mt-3"><Link to="/de/blog/"><strong>Mehr Artikel über Startups sehen</strong></Link></p>
        {/* </div>
      </div>
      <div className="col-12" style={{backgroundColor:"#283747"}}>
              <div className="row" style={{color:"white"}}>
                <div className="container">
                  <h2 style={{color:"white",marginTop:"10px"}}>Términos Populares</h2>
                  <p style={{color:"darkgray"}}>ola ka ase</p>
                </div>
                 */}
              </div>
            </div>
   
     </Layout>
  </I18nProvider>
)

export const query = graphql`
  query {
    allContentfulBlogPost(
      limit:6
      sort: { order: DESC, fields: updatedAt }
      filter: { lang: { eq: "de" } }
    ) {
      edges {
        node {
          lang
          title
          excerpt
          description {
            description
          }
          slug
          heroImage {
            fluid(
              maxHeight: 253
              maxWidth: 450
              quality: 60
              cropFocus: CENTER
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
function getIndexPath(lang) {
  return lang === "es" ? "" : `${lang}/`
}

function getBlogPath(lang) {
  return lang === "es" ? "/blog" : `/${lang}/blog`
}
